import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { motion } from "framer-motion"

const Container = styled.div`
    position: absolute;
    top: 1rem;
    left: 1rem;

    width: 100%;
    color: ${(props) => props.theme.text};
    z-index: 5;

    a {
        display: flex;
        align-items: flex-end;
    }

    svg {
        width: 4rem;
        height: auto;
        overflow: visible;
        stroke-linejoin: round;
        stroke-linecap: round;

        g {
            path {
                stroke: ${(props) => props.theme.text};
            }
        }
    }
`

const Text = styled(motion.span)`
    font-size: ${(props) => props.theme.fontlg};
    color: ${(props) => props.theme.text};
    padding: 0.5rem;
    font-family: "BebasNeue";
`

const textVariants = {
    hidden: {
        opacity: 0,
        x: -50,
    },
    visible: {
        opacity: 1,
        x: -5,

        transition: {
            duration: 2,
            delay: 2.5,
            ease: "easeInOut",
        },
    },
}

const pathVariants = {
    hidden: {
        opacity: 0,
        pathLength: 0,
    },
    visible: {
        opacity: 1,
        pathLength: 1,

        transition: {
            duration: 2,
            delay: 2.5,
            ease: "easeInOut",
        },
    },
}

const Logo = () => {
    return (
        <Container>
            <Link to="/">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    enableBackground="new 0 0 216 271.3"
                    height="48px"
                    viewBox="0 0 216 271.3"
                    width="48px"
                    strokeWidth={5}
                    fill="none"
                >
                    <g>
                        <motion.path
                            variants={pathVariants}
                            initial="hidden"
                            animate="visible"
                            d="M131.72,124.94l15.96,29.72-22.35-11.89-17.34,128.52-17.34-128.52-22.35,11.89,15.96-29.72L0,107.88l77.07-15.83-8.5-20.15,22.25,11.81L108,0l17.19,83.72,22.25-11.81-8.5,20.15,77.07,15.83-84.28,17.06Z"
                        />
                    </g>
                </svg>
                <Text variants={textVariants} initial="hidden" animate="visible">
                    Outerspace Studio
                </Text>
            </Link>
        </Container>
    )
}

export default Logo
