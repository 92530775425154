import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useLocomotiveScroll } from "react-locomotive-scroll";
import CoverVideo2 from "../components/CoverVideo2";

import Logo from "../assets/Svgs/logo2.svg";

import {
  AiFillInstagram,
  AiFillTwitterCircle,
  AiFillGithub,
} from "react-icons/ai";

const Section = styled.section`
  min-height: 100vh;
  width: 100vw;
  margin-bottom: 5rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;

  color: ${(props) => props.theme.text};

  position: relative;
  overflow: hidden;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 10vw;
    height: auto;
  }
  h3 {
    font-size: ${(props) => props.theme.fontxxl};
    font-family: "BebasNeue";

    @media (max-width: 64em) {
      font-size: ${(props) => props.theme.fontxl};
    }
    @media (max-width: 48em) {
      font-size: ${(props) => props.theme.fontlg};
    }
  }

  @media (max-width: 48em) {
    img {
      width: 22.5vw;
    }
  }
`;

const SocialIcon = styled.a`
  object-fit: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.5rem;
`;

const SocialIconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FooterComponent = styled(motion.footer)`
  width: 80vw;

  @media (max-width: 48em) {
    width: 90vw;
  }

  ul {
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: 2rem;
    margin-top: 4rem;
    padding: 0 1rem;
    border-top: 1px solid ${(props) => props.theme.text};
    border-bottom: 1px solid ${(props) => props.theme.text};

    @media (max-width: 48em) {
      justify-content: center;
    }
  }

  li {
    padding: 2rem;
    font-size: ${(props) => props.theme.fontlg};
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      transform: scale(1.1);
    }

    @media (max-width: 48em) {
      padding: 1rem;
      font-size: ${(props) => props.theme.fontmd};
    }
  }
`;

const Bottom = styled.div`
  width: 100%;
  padding: 1rem 0;
  margin: 1rem;
  font-size: ${(props) => props.theme.fontlg};

  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    text-decoration: underline;
  }

  @media (max-width: 64em) {
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin: 0;

    span {
      transform: none !important;
    }
  }
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontmd};
  }
`;

const Footer = () => {
  const { scroll } = useLocomotiveScroll();

  const handleScroll = (id) => {
    let elem = document.querySelector(id);

    scroll.scrollTo(elem, {
      offset: "-100",
      duration: "2000",
      easing: [0.25, 0.0, 0.35, 1.0],
    });
  };

  return (
    <>
      <CoverVideo2 />

      <Section className="panel">
        <LogoContainer>
          <img
            data-scroll
            data-scroll-speed="2"
            src={Logo}
            alt="Outerspace Studio"
          />
          <h3 data-scroll data-scroll-speed="-1">
            Outerspace Studio
          </h3>
        </LogoContainer>
        <FooterComponent
          initial={{ y: "-400px" }}
          whileInView={{ y: 0 }}
          viewport={{ once: false }}
          transition={{
            duration: 1.5,
          }}
        >
          <ul>
            <li onClick={() => handleScroll("#home")}>home</li>
            <li onClick={() => handleScroll("#service")}>service</li>
            <li onClick={() => handleScroll(".about")}>about</li>
            <li onClick={() => handleScroll("#gallery")}>partner</li>
            {/* <li onClick={() => handleScroll("#contact")}>contact</li> */}
          </ul>
          <Bottom>
            <span
              data-scroll
              data-scroll-speed="2"
              data-scroll-direction="horizontal"
            >
              &copy; {new Date().getFullYear()}. All Rights Reserved. &nbsp;
            </span>
            <SocialIconBox>
              <SocialIcon
                href="https://www.instagram.com/outerspaceweb3/"
                target="_blank"
                rel="noreferrer"
              >
                <AiFillInstagram size={32} />
              </SocialIcon>
              <SocialIcon
                href="https://twitter.com/OuterspaceSTU/"
                target="_blank"
                rel="noreferrer"
              >
                <AiFillTwitterCircle size={32} />
              </SocialIcon>

              <SocialIcon
                href="https://www.instagram.com/outerspaceweb3/"
                target="_blank"
                rel="noreferrer"
              >
                <AiFillGithub size={32} />
              </SocialIcon>
            </SocialIconBox>

            <span
              data-scroll
              data-scroll-speed="-2"
              data-scroll-direction="horizontal"
            >
              Made with &hearts; by &nbsp;
              <a href="https://outerspace.ai/" target="_blank" rel="noreferrer">
                Outerspace Studio
              </a>
            </span>
          </Bottom>
        </FooterComponent>
      </Section>
    </>
  );
};

export default Footer;
