import React from "react";
import styled from "styled-components";
import Carousel from "../components/Carousel";

const Section = styled.section`
  min-height: 100vh;
  width: 100%;
  background-color: ${(props) => props.theme.body};

  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
`;

const Container = styled.div`
  width: 75%;
  margin: 0 auto;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 64em) {
    width: 85%;
  }
  @media (max-width: 64em) {
    width: 100%;
    flex-direction: column;

    & > *:last-child {
      width: 80%;
    }
  }
  @media (max-width: 40em) {
    & > *:last-child {
      width: 90%;
    }
  }
`;

const Box = styled.div`
  width: 50%;
  height: 100%;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 40em) {
    min-height: 50vh;
  }
`;

const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxxl};
  font-family: "BebasNeue";
  font-weight: 300;
  text-shadow: 1px 1px 1px ${(props) => props.theme.body};
  color: ${(props) => props.theme.text};
  position: absolute;
  top: 1rem;
  left: 5%;
  z-index: 11;

  @media (max-width: 64em) {
    font-size: ${(props) => props.theme.fontxxxl};
  }
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontxxl};
  }
`;


const Gallery = () => {
  return (
    <Section id="gallery" className="panel">
      <Title data-scroll data-scroll-speed="-1">
        Our Partner
      </Title>
      <Container>
        <Box>
          <Carousel />
        </Box>
        {/* <Box> 
                    <Title data-scroll data-scroll-speed="-1">
                        Our Partner
                    </Title>
                    <SubText data-scroll data-scroll-speed="-1">
                    Miss Cloud wears our six hot shades from Cloud Touch Fixing Tints that delivers a fresh and brilliant hydro-matte color like a Korean fairy. Check out our hot shades:<br/>
                    </SubText>
                    <SubTextLight data-scroll data-scroll-speed="-1">
                    #R199 AIRY KISS: a sheer strawberry pink<br/>
                    #P796 MISTY NIGHT: a daring reddish plum<br/>
                    #R868 SUN'N FUN: an eye-catching peachy orange<br/>
                    #R567 BREZZY DAY: an enchanted red maroon<br/>
                    #R688 BLUSHING SKY: a soft and timeless rosewood<br/>
                    #R667 PUFFY SKY: a vintage vibe with red teracotta<br/>
                    </SubTextLight>
                    <ButtonContainer data-scroll data-scroll-speed="-1">
                        <ThemeProvider theme={dark}>
                        <a href="https://www.tiktok.com/tag/youcloudtouch" target='_blank' rel="noopener">
                        <Btn>#YOUCloudTouch on TikTok</Btn>
                        </a>
                        </ThemeProvider>
                    </ButtonContainer>

                </Box> */}
      </Container>
    </Section>
  );
};

export default Gallery;
