import React from "react"
import styled from "styled-components"
import "swiper/css"
import "swiper/css/effect-cards"
import "swiper/css/pagination"
import "swiper/css/navigation"

import img1 from "../assets/Images/showroomposter.gif"
import img2 from "../assets/Images/project1.webp"
import img3 from "../assets/Images/project3.webp"
import img4 from "../assets/Images/project7.webp"
import img5 from "../assets/Images/project4.webp"

const Container = styled.div`
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    padding-top: 10rem;
`

const BoxContainer = styled.div`
    width: 67%;
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;
    margin: 2rem 0;
    border-radius: 12px;
    padding: 2rem 1.5rem;

    @media (max-width: 64em) {
        margin-bottom: 0;
        padding: 1.5rem 0;
        width: 80%;
    }
`

const ImgContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        object-fit: contain;
        width: 20rem;
        height: 20rem;
        background-color: white;
        padding: 1rem;
        border-radius: 12px;

        @media (max-width: 48em) {
            width: 16rem;
            height: 16rem;
        }
    }

    &:hover {
        scale: 1.03;
        transition-duration: 0.1s;
    }
`

const TextContainer = styled.div`
    width: 85%;
    align-items: center;
    color: ${(props) => props.theme.text};

    h1 {
        padding-top: 1.5rem;
        font-size: ${(props) => props.theme.fontlg};
        text-align: center;
        font-weight: 600;
    }

    p {
        font-size: ${(props) => props.theme.fontmd};
        text-align: center;
        padding: 1rem 0;

        @media (max-width: 64em) {
            width: 100%;
        }
    }
`

const Wrapper = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    align-items: top;
    margin-bottom: 6rem;

    @media (max-width: 64em) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-items: center;
        align-items: top;
    }
    @media (max-width: 48em) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 2rem;
        margin-top: 0rem;
    }
`

const Carousel = () => {
    return (
        <Container>
            <Wrapper>
                <BoxContainer>
                    <ImgContainer>
                        <img src={img2} alt="Miss Cloud x Y.O.U Beauty" />
                    </ImgContainer>
                    <TextContainer>
                        <h1>Miss Cloud (Y.O.U Beauty)</h1>
                        <p>
                            Miss Cloud celebrates every unique feminine energy of softness, free
                            spirits, and independence like clouds. Y.O.U collaborated with Korean
                            female artists ddangking launched the exclusive collection of Miss
                            Cloud NFTs in September 2022.
                        </p>
                    </TextContainer>
                </BoxContainer>

                <BoxContainer>
                    <ImgContainer>
                        <img src={img1} alt="Sshowroom" />
                    </ImgContainer>
                    <TextContainer>
                        <h1>Sshowroom</h1>
                        <p>
                            Sshowroom is a Web3 community aiming to create a space to foster
                            cryptoart and gather interesting minds. We aim to connect the emerging
                            digital technologies to our lifestyle, and bring impressive visual
                            experiences into future art world.
                        </p>
                    </TextContainer>
                </BoxContainer>
                <BoxContainer>
                    <ImgContainer>
                        <img src={img3} alt="Planetverse" />
                    </ImgContainer>
                    <TextContainer>
                        <h1>Planetverse</h1>
                        <p>
                            The Tyskos mission is to find if other Tyskos had survived out on other
                            far off planets, so far nothing has been found. Though the mission
                            still continues with the eventual hopes of finding a new home and
                            finding there brother and sisters far off amongst the stars.
                        </p>
                    </TextContainer>
                </BoxContainer>
                <BoxContainer>
                    <ImgContainer>
                        <img src={img4} alt="Adamash" />
                    </ImgContainer>
                    <TextContainer>
                        <h1>Adamash</h1>
                        <p>Limited Edition digital colectible toys NFT Project.</p>
                    </TextContainer>
                </BoxContainer>
                <BoxContainer>
                    <ImgContainer>
                        <img src={img5} alt="Signet" />
                    </ImgContainer>
                    <TextContainer>
                        <h1>SIGNET</h1>
                        <p>
                            Signet is a all-in-one web3 social media platform that supports text
                            and image content posting, profile picture authentication, instant
                            crypto transfer. Share ideas, meet new people, stay updated with your
                            commiunity, and send crypto to anyone anywhere around the world.
                        </p>
                    </TextContainer>
                </BoxContainer>
            </Wrapper>
        </Container>
    )
}

export default Carousel
