import React from 'react'
import styled from 'styled-components'
import { motion } from "framer-motion";

const Container = styled(motion.div)`
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;

touch-action: none;
overflow: hidden;

width: 100vw;
height: 100vh;

z-index: 20;

display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

background-color: ${props => props.theme.body};
color: ${props => props.theme.text};

svg {
    width: 12vw;
    height: auto;
    overflow: visible;
    stroke-linejoin: round;
    stroke-linecap: round;

    g {
      path {
        stroke: ${(props) => props.theme.text};
      }
    }
  }

  @media (max-width: 48em) {
    svg{
      width: 20vw;
    }
  }
`

const Text = styled(motion.span)`
  font-size: ${(props) => props.theme.fontxl};
  color: ${(props) => props.theme.text};
  font-family: "BebasNeue";
  padding: 0.5rem;

  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontlg};
  }
`;

const textVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
  
      transition: {
        duration: 2,
        yoyo: Infinity,
        ease: "easeInOut",
      },
    },
  };

const pathVariants = {
  hidden: {
    opacity: 0,
    pathLength: 0,
  },
  visible: {
    opacity: 1,
    pathLength: 1,

    transition: {
      duration: 1,
      ease: "easeInOut",
    },
  },
};

const Loader = () => {
  return (
    <Container
    initial={{y:0, opacity:1}}
    exit={{y:'100%', opacity:0}}
    transition={{duration:2}}
    >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          enableBackground="new 0 0 216 271.3"
          height="48px"
          viewBox="0 0 216 271.3"
          width="48px"
          strokeWidth={5}
          fill="none"
        >
          <g>
            <motion.path
              variants={pathVariants}
              initial="hidden"
              animate="visible"
              d="M131.72,124.94l15.96,29.72-22.35-11.89-17.34,128.52-17.34-128.52-22.35,11.89,15.96-29.72L0,107.88l77.07-15.83-8.5-20.15,22.25,11.81L108,0l17.19,83.72,22.25-11.81-8.5,20.15,77.07,15.83-84.28,17.06Z"
            />
          </g>
        </svg>
        <Text               
            variants={textVariants}
            initial="hidden"
            animate="visible">
            Outerspace Studio</Text>
    </Container>
  )
}

export default Loader