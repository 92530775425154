import React, { useRef, useState, useEffect } from "react"
import styled from "styled-components"

import WalkingOnMarsVideo from "../assets/WalkingOnMarsVideo.mp4"
import mobileBg from "../assets/SloganGif3.gif"

const VideoContainer = styled.section`
    width: 100%;
    height: 100vh;
    position: relative;

    video {
        width: 100%;
        height: 100vh;
        object-fit: cover;

        @media (max-width: 48em) {
            object-position: center 10%;
        }
    }
    @media (max-width: 30em) {
        object-position: center 50%;
    }
`

const DarkOverlay = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;

    background-color: ${(props) => `rgba(${props.theme.indigoRgba},0.44)`};
`

const GifContainer = styled.div`
    height: 100vh;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        height: 100%;
    }
`

const CoverVideo4 = () => {
    const videoRef = useRef()
    const setPlayBack = () => {
        videoRef.current.playbackRate = 1
    }

    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth)
        }

        window.addEventListener("resize", handleWindowResize)

        return () => {
            window.removeEventListener("resize", handleWindowResize)
        }
    }, [])

    const DesktopBackground = () => {
        return (
            <video
                ref={videoRef}
                onCanPlay={() => setPlayBack()}
                src={WalkingOnMarsVideo}
                type="video/mp4"
                autoPlay
                muted
                loop
            />
        )
    }

    const MobileBackground = () => {
        return (
            <GifContainer>
                <img src={mobileBg} alt="" />
            </GifContainer>
        )
    }

    return (
        <VideoContainer>
            <DarkOverlay />
            {windowWidth >= 650 ? <DesktopBackground /> : <MobileBackground />}
        </VideoContainer>
    )
}

export default CoverVideo4
