import React, { useRef, useState, useEffect } from "react"
import styled from "styled-components"
import { motion } from "framer-motion"

import DigiWorldVideo from "../assets/DigiWorldVideo.mp4"
import mobileBg from "../assets/HomeGif.gif"

const VideoContainer = styled.section`
    width: 100%;
    height: 100vh;
    position: relative;

    video {
        width: 100%;
        height: 100vh;
        object-fit: cover;

        @media (max-width: 48em) {
            object-position: center 10%;
        }
    }
    @media (max-width: 30em) {
        object-position: center 50%;
    }
`

const DarkOverlay = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;

    background-color: ${(props) => `rgba(${props.theme.indigoRgba},0.66)`};
`

const Title = styled(motion.div)`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.theme.text};

    div {
        display: flex;
        flex-direction: row;
    }

    h1 {
        font-family: "BebasNeue";
        font-size: ${(props) => props.theme.fontBig};
        text-shadow: 3px 2px 5px ${(props) => props.theme.body};

        @media (max-width: 64em) {
            font-size: ${(props) => props.theme.fontxxxl};
        }
        @media (max-width: 40em) {
            font-size: ${(props) => props.theme.fontxxl};
        }
        @media (max-width: 30em) {
            font-size: ${(props) => props.theme.fontxxl};
        }
    }
    h2 {
        font-family: "NunitoSansRegular";
        font-size: ${(props) => props.theme.fontxl};
        text-shadow: 1px 1px 1px ${(props) => props.theme.body};
        font-weight: 300;
        text-transform: capitalize;

        @media (max-width: 64em) {
            font-size: ${(props) => props.theme.fontxl};
        }
        @media (max-width: 30em) {
            font-size: ${(props) => props.theme.fontlg};
            margin-top: 0.5rem;
        }
    }
`

const GifContainer = styled.div`
    height: 100vh;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        height: 100%;
    }
`

const container = {
    hidden: {
        opacity: 0,
    },
    show: {
        opacity: 1,

        transition: {
            delayChildren: 3.5,
            staggerChildren: 0.12,
        },
    },
}

const item = {
    hidden: {
        opacity: 0,
    },
    show: {
        opacity: 1,
    },
}

const CoverVideo = () => {
    const videoRef = useRef()
    const setPlayBack = () => {
        videoRef.current.playbackRate = 1
    }

    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth)
        }

        window.addEventListener("resize", handleWindowResize)

        return () => {
            window.removeEventListener("resize", handleWindowResize)
        }
    }, [])

    const DesktopBackground = () => {
        return (
            <video
                ref={videoRef}
                onCanPlay={() => setPlayBack()}
                src={DigiWorldVideo}
                type="video/mp4"
                autoPlay
                muted
                loop
            />
        )
    }

    const MobileBackground = () => {
        return (
            <GifContainer>
                <img src={mobileBg} alt="" />
            </GifContainer>
        )
    }
    return (
        <VideoContainer>
            <DarkOverlay />
            <Title variants={container} initial="hidden" animate="show">
                <div>
                    <motion.h1
                        variants={item}
                        data-scroll
                        data-scroll-speed="4"
                        data-scroll-delay="0.19"
                    >
                        O
                    </motion.h1>
                    <motion.h1
                        variants={item}
                        data-scroll
                        data-scroll-speed="4"
                        data-scroll-delay="0.18"
                    >
                        u
                    </motion.h1>
                    <motion.h1
                        variants={item}
                        data-scroll
                        data-scroll-speed="4"
                        data-scroll-delay="0.17"
                    >
                        t
                    </motion.h1>
                    <motion.h1
                        variants={item}
                        data-scroll
                        data-scroll-speed="4"
                        data-scroll-delay="0.16"
                    >
                        e
                    </motion.h1>
                    <motion.h1
                        variants={item}
                        data-scroll
                        data-scroll-speed="4"
                        data-scroll-delay="0.15"
                    >
                        r
                    </motion.h1>
                    <motion.h1
                        variants={item}
                        data-scroll
                        data-scroll-speed="4"
                        data-scroll-delay="0.14"
                    >
                        s
                    </motion.h1>
                    <motion.h1
                        variants={item}
                        data-scroll
                        data-scroll-speed="4"
                        data-scroll-delay="0.13"
                    >
                        p
                    </motion.h1>
                    <motion.h1
                        variants={item}
                        data-scroll
                        data-scroll-speed="4"
                        data-scroll-delay="0.12"
                    >
                        a
                    </motion.h1>
                    <motion.h1
                        variants={item}
                        data-scroll
                        data-scroll-speed="4"
                        data-scroll-delay="0.11"
                    >
                        c
                    </motion.h1>
                    <motion.h1
                        variants={item}
                        data-scroll
                        data-scroll-speed="4"
                        data-scroll-delay="0.10"
                    >
                        e
                    </motion.h1>
                    <motion.h1
                        variants={item}
                        data-scroll
                        data-scroll-speed="4"
                        data-scroll-delay="0.10"
                    >
                        &nbsp;
                    </motion.h1>
                    <motion.h1
                        variants={item}
                        data-scroll
                        data-scroll-speed="4"
                        data-scroll-delay="0.09"
                    >
                        S
                    </motion.h1>
                    <motion.h1
                        variants={item}
                        data-scroll
                        data-scroll-speed="4"
                        data-scroll-delay="0.08"
                    >
                        t
                    </motion.h1>
                    <motion.h1
                        variants={item}
                        data-scroll
                        data-scroll-speed="4"
                        data-scroll-delay="0.07"
                    >
                        u
                    </motion.h1>
                    <motion.h1
                        variants={item}
                        data-scroll
                        data-scroll-speed="4"
                        data-scroll-delay="0.06"
                    >
                        d
                    </motion.h1>
                    <motion.h1
                        variants={item}
                        data-scroll
                        data-scroll-speed="4"
                        data-scroll-delay="0.05"
                    >
                        i
                    </motion.h1>
                    <motion.h1
                        variants={item}
                        data-scroll
                        data-scroll-speed="4"
                        data-scroll-delay="0.045"
                    >
                        o
                    </motion.h1>
                </div>
                <motion.h2
                    variants={item}
                    data-scroll
                    data-scroll-speed="2"
                    data-scroll-delay="0.04"
                >
                    Top team of experts in Web3 dApps
                </motion.h2>
            </Title>

            {windowWidth >= 650 ? <DesktopBackground /> : <MobileBackground />}
        </VideoContainer>
    )
}

export default CoverVideo
