


export const dark = {
    body: '#202020',
    text: '#fff',
    bodyRgba: '32,32,32',
    textRgba: '255,255,255',
    indigoRgba: '4,26,47',
    tealRgba: '0,202,177',
    grey: '#bebebe',
    teal: '#00cab1',
    deepBlue: '#04152a',

    fontxxs: '0.5em',  //1em = 16px
    fontxs: '0.75em',
    fontsm: '0.875em',
    fontmd: '1em',
    fontlg: '1.25em',
    fontxl: '2em',
    fontxxl: '3em',
    fontxxxl: '5em',
    fontBig: '10em',

    navHeight: '5rem'
}