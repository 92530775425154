import { createGlobalStyle } from "styled-components";
import "@fontsource/kaushan-script";
import "@fontsource/sirin-stencil";
import NunitoSansRegular from "../fonts/NunitoSans-Regular.ttf"
import NunitoSansBlack from "../fonts/NunitoSans-Black.ttf"
import BebasNeue from "../fonts/BebasNeue-Regular.ttf"


const GlobalStyles = createGlobalStyle`

@font-face {
font-family: "BebasNeue";
src: local("BebasNeue-Regular"),
 url(${BebasNeue}) format("truetype");
}

@font-face {
font-family: "NunitoSansRegular";
src: local("NunitoSans-Regular"),
 url(${NunitoSansRegular}) format("truetype");
}

@font-face {
font-family: "NunitoSansBlack";
src: local("NunitoSans-Black"),
 url(${NunitoSansBlack}) format("truetype");
}

*,*::before,*::after{
    margin: 0;
    padding: 0;

}

body{
    font-family: "NunitoSansRegular";
    overflow-x: hidden;
}

h1,h2,h3,h4,h5,h6{
    margin: 0;
    padding: 0;
}

a{
    color: inherit;
    text-decoration: none;
}

`
export default GlobalStyles;