import React from "react"
import styled from "styled-components"

import { motion } from "framer-motion"

import scLogo from "../assets/serviceLogo/scLogo.png"
import dappLogo from "../assets/serviceLogo/dappLogo.png"
import artLogo from "../assets/serviceLogo/artLogo.png"
import webLogo from "../assets/serviceLogo/webLogo.png"
import botLogo from "../assets/serviceLogo/botLogo.png"
import customLogo from "../assets/serviceLogo/customLogo.png"

const Section = styled.section`
    min-height: 100vh;
    width: 100vw;
    margin: 0 auto;
    overflow: hidden;

    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: ${(props) => props.theme.body};

    position: relative;
`

const Container = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    align-items: top;
    margin-bottom: 4rem;
    margin-top: 12rem;

    @media (max-width: 64em) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 0;
        margin-top: 6rem;
    }
`

const Title = styled.h1`
    font-size: ${(props) => props.theme.fontxxxl};
    font-family: "BebasNeue";
    font-weight: 300;
    text-shadow: 1px 1px 1px ${(props) => props.theme.body};
    color: ${(props) => props.theme.text};
    position: absolute;
    top: 1rem;
    left: 5%;
    z-index: 11;

    @media (max-width: 64em) {
        font-size: ${(props) => props.theme.fontxxl};
    }
    @media (max-width: 48em) {
        font-size: ${(props) => props.theme.fontxl};
    }
`

const Item = styled(motion.div)`
    width: 28rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 6rem;

    @media (max-width: 64em) {
        margin-bottom: 4rem;
    }

    img {
        width: 60%;
        height: auto;
        cursor: pointer;
        background-color: ${(props) => props.theme.text};
        border-radius: 10%;
        padding: 3rem;

        @media (max-width: 64em) {
            width: 75%;
        }
    }
    h1 {
        width: 80%;
        margin-top: 2rem;
        display: inline-block;
        font-weight: 500;
        text-align: center;
        cursor: pointer;
        color: ${(props) => props.theme.text};
        font-weight: 600;
        font-size: ${(props) => props.theme.fontlg};

        @media (max-width: 64em) {
            width: 100%;
        }
        @media (max-width: 48em) {
            font-size: ${(props) => props.theme.fontmd};
        }
    }

    @media (max-width: 48em) {
        width: 15rem;
    }

    p {
        color: ${(props) => props.theme.text};
        margin-top: 1rem;
        width: 60%;
        text-align: center;

        @media (max-width: 64em) {
            width: 100%;
        }
    }
`

const Product = ({ img, title = "", desc = "" }) => {
    return (
        <Item
            initial={{ filter: "grayscale(100%)" }}
            whileInView={{ filter: "grayscale(0%)" }}
            transition={{ duration: 0.5 }}
            viewport={{ once: false, amount: "all" }}
        >
            <img src={img} alt={title} />
            <h1>{title}</h1>
            <p>{desc}</p>
        </Item>
    )
}

const Service2 = () => {
    return (
        <Section id="service" className="panel">
            <Title data-scroll data-scroll-speed="-1">
                Our Service
            </Title>
            <Container>
                <Product
                    img={dappLogo}
                    title="DECENTRALIZED WEB APPLICATION DEVELOPMENT"
                    desc="We build secure and unchangeable programs running on a decentralized network in combination with traditional front-end and back-end technologies."
                />
                <Product
                    img={scLogo}
                    title="SMART CONTRACT DEVELOPMENT AND DEPLOYMENT"
                    desc="We write self-executing smart contracts with the terms of the agreement between buyer and seller for it to be distributed on decentralized blockchain network."
                />
                <Product
                    img={artLogo}
                    title="DIGITAL ART CREATION AND ALGORITHM GENERATION"
                    desc="we create and use AI to generate thousands of digital artworks for your NFT project, tell us what art style you like."
                />
                <Product
                    img={webLogo}
                    title="GENERAL WEB APPLICATION DEVELOPMENT"
                    desc="We also build semi-decentralized and traditional web applications for your online business, e-commerce, Video Game, etc."
                />
                <Product
                    img={botLogo}
                    title="BLOCKCHAIN BOTS (MASS OFFER/BID BOT, CONTRACT MINTER, ETC.)"
                    desc="We build automatd bots to improve your and your team's efficiency when interacting with blockchain network."
                />
                <Product
                    img={customLogo}
                    title="SEND US YOUR CUSTOM ORDER REQUEST"
                    desc="Ask us anything about blockchain development, tell us how we can help you to achieve your web3 dream. Email: inqury@outerspace.ai "
                />
            </Container>
        </Section>
    )
}

export default Service2
