import { ThemeProvider } from "styled-components"
import GlobalStyles from "./styles/GlobalStyles"
import { dark } from "./styles/Theme"
import { LocomotiveScrollProvider } from "react-locomotive-scroll"
import { useRef, useState, useEffect } from "react"
import { AnimatePresence } from "framer-motion"
import "locomotive-scroll/dist/locomotive-scroll.css"

import Home from "./sections/Home"
import About from "./sections/About"
import Banner from "./sections/Banner"
import Footer from "./sections/Footer"
import Service2 from "./sections/Service2"
import Gallery from "./sections/Gallery"
import Loader from "./components/Loader"

function App() {
    const containerRef = useRef(null)

    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        const onPageLoad = () => {
            setTimeout(() => {
                setLoaded(true)
            }, 2500)
        }

        // Check if the page has already loaded
        if (document.readyState === "complete") {
            onPageLoad()
        } else {
            window.addEventListener("load", onPageLoad)
            // Remove the event listener when component unmounts
            return () => window.removeEventListener("load", onPageLoad)
        }
    }, [])

    return (
        <>
            <GlobalStyles />
            <ThemeProvider theme={dark}>
                <LocomotiveScrollProvider
                    options={{
                        smooth: true,
                        // ... all available Locomotive Scroll instance options
                        smartphone: {
                            smooth: true,
                        },
                        tablet: {
                            smooth: true,
                        },
                    }}
                    watch={
                        [
                            //..all the dependencies you want to watch to update the scroll.
                            //  Basicaly, you would want to watch page/location changes
                            //  For exemple, on Next.js you would want to watch properties like `router.asPath` (you may want to add more criterias if the instance should be update on locations with query parameters)
                        ]
                    }
                    containerRef={containerRef}
                >
                    <AnimatePresence>{loaded ? null : <Loader />}</AnimatePresence>

                    {/* <ScrollTriggerProxy /> */}
                    <AnimatePresence>
                        {/* <DownArrow /> */}
                        <main className="App" data-scroll-container ref={containerRef}>
                            <Home />
                            <Banner />
                            <Service2 />
                            <About />
                            <Gallery />
                            {/* <NewArrival /> */}
                            {/* <Showcase /> */}

                            {/* <Contact /> */}
                            <Footer />
                        </main>
                    </AnimatePresence>
                </LocomotiveScrollProvider>
            </ThemeProvider>
        </>
    )
}

export default App
