import React from "react";
import styled from "styled-components";
import CoverVideo3 from "../components/CoverVideo3";

import img2 from "../assets/Images/img9.webp";
import img3 from "../assets/Images/img6.webp";
import img4 from "../assets/Images/img8.webp";
import adaIcon from "../assets/Images/adaIcon.webp";

const Background = styled.section`
  position: relative;
  width: 100vw;
  min-height: 100vh;
  overflow: hidden;
`;

const Sections = styled.section`
  position: absolute;
  top: 0%;
  left: 0%;
  min-height: 100vh;
  width: 80vw;
  display: flex;
  margin: 0 auto;
  z-index: 33;

  @media (max-width: 48em) {
    width: 90vw;
  }
  @media (max-width: 30em) {
    width: 100vw;
  }
`;

const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxxl};
  font-family: "BebasNeue";
  font-weight: 300;
  color: ${(props) => props.theme.text};
  z-index: 33;

  position: absolute;
  top: 3rem;
  right: 25%;
  z-index: 5;

  @media (max-width: 64em) {
    font-size: ${(props) => `calc(${props.theme.fontxxxl} - 5vw)`};
    top: 1.5rem;
    left: 0;
  }
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontxxl};
  }
`;

const Left = styled.div`
  width: 33%;
  font-size: ${(props) => props.theme.fontlg};
  color: ${(props) => props.theme.text};
  text-align: left;
  position: relative;
  z-index: 5;
  margin-top: 20%;
  padding: 0 3rem;
  left: 60%;

  @media (max-width: 64em) {
    width: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    margin: 0 auto;

    padding: 2rem;

    backdrop-filter: blur(2px);
    background-color: ${(props) => `rgba(${props.theme.textRgba}, 0.4)`};
    border-radius: 20px;
  }

  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontmd};
  }
  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontsm};
    width: 70%;
  }
`;

const Right = styled.div`
  width: 60%;
  position: relative;

  img {
    width: 100%;
    height: auto;
  }

  .small-img-main {
    height: 100%;
    object-fit: cover;
  }

  .small-img-1 {
    width: 30%;
    position: absolute;
    right: 75%;
    bottom: 10%;
  }
  .small-img-2 {
    width: 27%;
    position: absolute;
    left: 105%;
    bottom: 40%;
  }
  .small-img-3 {
    width: 30%;
    position: absolute;
    right: 75%;
    bottom: 30%;
  }
  .small-img-4 {
    width: 30%;
    position: absolute;
    left: 105%;
    bottom: 5%;
  }

  @media (max-width: 64em) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 100vh;
      object-fit: cover;
    }
    .small-img-1 {
      width: 25%;
      height: auto;
      left: 5%;
      bottom: 10%;
    }
    .small-img-2 {
      width: 25%;
      height: auto;
      left: 60%;
      bottom: 25%;
    }
    .small-img-3 {
      width: 25%;
      height: auto;
      left: 5%;
      bottom: 30%;
    }
    .small-img-4 {
      width: 25%;
      height: auto;
      left: 60%;
      bottom: 55%;
    }
  }
`;

const About = () => {
  return (
    <Background>
      <CoverVideo3 />
      <Sections id="fixed-target" className="about">
        <Title
          data-scroll
          data-scroll-speed="-2"
          data-scroll-direction="horizontal"
        >
          Blockchain Development
        </Title>
        <Left data-scroll data-scroll-sticky data-scroll-target="#fixed-target">
          <p>
            We believe in technology. But most importantly, we believe in people
            with great ideas, and those who are not afraid to lead the way.
            Formed by a few blockchain specialists with the same vision - make
            blockchain network more accessible to everyone, Outerspace studio
            team dedicates to deliver clients with the best blockchain apps that
            are interactive, up-to-date, and easy.
            <br />
            <br />
            Our expertise covers Front-end web Development, Back-end Web
            Deveopment, Smart Contract, Solidity, Cybersecurity, Database. With
            our professional skill sets, Outerspace studio has helped many
            individuals and organizations successfully lauched their first Web3
            projects on Ethereum Network, Polygon Network, Solana Network, and
            Cardano Network.
            <br />
            <br />
          </p>
        </Left>
        <Right>
          <img
            data-scroll
            data-scroll-speed="-2"
            src={img2}
            className="small-img-4"
            alt="About Us"
          />
          <img
            data-scroll
            data-scroll-speed="-2"
            src={img3}
            className="small-img-2"
            alt="About Us"
          />
          <img
            data-scroll
            data-scroll-speed="2"
            src={img4}
            className="small-img-3"
            alt="About Us"
          />
          <img
            data-scroll
            data-scroll-speed="2"
            src={adaIcon}
            className="small-img-1"
            alt="About Us"
          />
        </Right>
      </Sections>
    </Background>
  );
};

export default About;
